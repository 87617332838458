import React from 'react'
import './Creatives.css'
import { creativeData } from '../Data/ImageData'
export default function Creatives() {
  return (
    <div className='crative-Container'>
      {
        creativeData.map((e)=>{
          return(
            <img className='Creative-img' src={process.env.PUBLIC_URL+e.img}/>
          )
        })
      }
        {/* <img className='Creative-img' src={process.env.PUBLIC_URL+'./Creatives/Creative1.png'}/>
        <img className='Creative-img' src={process.env.PUBLIC_URL+'./Creatives/Creative2.png'}/>
        <img className='Creative-img' src={process.env.PUBLIC_URL+'./Creatives/Creative3.png'}/>
        <img className='Creative-img' src={process.env.PUBLIC_URL+'./Creatives/Creative4.png'}/>
        <img className='Creative-img' src={process.env.PUBLIC_URL+'./Creatives/Creative5.png'}/>
        <img className='Creative-img' src={process.env.PUBLIC_URL+'./Creatives/Creative6.png'}/> */}
    
    </div>
  )
}
