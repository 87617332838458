import React from 'react'
import { fData } from '../Data/SpecificationData'
import './Feature.css'
export default function Feature() {
  return (
    <div className='feature-container'>
      {
        fData.length!==0?
        fData.map((content,index)=>{
            return(
                   <div key={index} className='single-faeture-text-conainer'>
                     <div className='feature-text'>{content.left}</div>
                     <div className='feature-text'>{content.right}</div>
                   </div>
            )
        }):null
      }
    </div>
  )
}
