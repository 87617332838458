import axios from "axios";

export const cleanNumber = (num = "") => {
  try {
    let cn = String(num).replace(/\D/g, ""); // replace non digits
    if (cn.startsWith("0")) {
      // the number is starting with 0, remove it
      cn = cn.slice(1, cn.length);
    }
    if (cn.length > 10) {
      cn = cn.slice(cn.length - 10, cn.length);
    }
    return `+91${cn}`;
  } catch (_) {
    return num;
  }
};

export const alisteLead = (contact) => {
  axios
    .post(
      "https://8fza561rd2.execute-api.ap-south-1.amazonaws.com/default/lead/aliste",
      { ...contact }
    )
    .then(() => {})
    .catch(() => {});
};
