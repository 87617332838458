import {
  userResposeDataReducer,
  userPlanNameReducer,
  completeDataReducer,
  mobileViewPlanDetailReducer,
  getFaqReducer,
  getReviewReducer,
} from "./reducer";
import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
const persistConfig = {
  key: "persist-key",
  storage,
};
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    userResonse: userResposeDataReducer,
    userPlan: userPlanNameReducer,
    completeData: completeDataReducer,
    mobilePlanData: mobileViewPlanDetailReducer,
    faqData: getFaqReducer,
    reviewData: getReviewReducer,
  })
);

let store = createStore(persistedReducer);
let persistor = persistStore(store);
export default store;
export { persistor };
