import axios from "axios"
import { serverUrl } from "../../ApiUrl";



export const getVoucherStatusApi = async (code,billValue) => {
    return await axios.post(`${serverUrl.giftr}/getStatus`, {
        voucherCode: code.current.value,
        billValue:billValue
    }).then(res => {
        console.log(res.data, "status");
        return res.data;
    }).catch(err => {
        console.log(err, "error");
        return { success: false, message: "something went wrong" }
    })
}

export const consumeVocherCodeApi = async (email,phone,code,billAmount) => {
    return await axios.post(`${serverUrl.giftr}/consume`, {
        phone: phone,
        email: email,
        voucherCode: code,
        billValue:billAmount,
    }).then(res => {
        console.log(res.data, "status");
        return res.data;
    }).catch(err => {
        console.log(err, "error");
        return { success: false, message: "something went wrong" }
    })
}

export const registerVoucherClient = async (detail)=>{
    await axios.post(`${serverUrl.u}/api/zoho/registerPartialOfflinePaidClient`,detail).then(
        async(res)=> {
        // console.log(res,"paid res");
        if(res.data.success===true){
           await UpdateHex(detail.transactionId,res.data.data.subscription.generatedHex)
        }
    }).catch(err=>{
        // console.log(err,"paid err");
    })
}

const UpdateHex = async(code,hex)=>{
   await axios.post(`${serverUrl.giftr}/updateHex`,{
    "voucherCode": code,
    "subscriptionHex": hex,
   }).then(res=>{
    // console.log(res,"update hex res");
    if(res.data.success===true){
        window.location = `voucher/${hex}`
    }
   }).catch(err=>{
    // console.log(err,"update hex err");
   })
}