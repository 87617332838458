import React from 'react'
import './OurClients.css'
import Marquee from "react-fast-marquee";
export default function OurClients() {
  return (
    


    <div className='OurClients-container'>
        <div className='OurClients-haeding'>Our Clients</div>
        <div className='ourClient'>
           {/* <marquee loop  behavior="scroll" className="marquee-client"> */}
           <Marquee className="marquee-client" speed={60}>
            <div className='client-logo-container'>
              <img className='ourClientImg1' src={process.env.PUBLIC_URL+'./Clients/somany.png'}/>
              <img className='ourClientImg2' src={process.env.PUBLIC_URL+'./Clients/taj.png'}/>
              <img className='ourClientImg3' src={process.env.PUBLIC_URL+'./Clients/gyftr.png'}/>
              <img className='ourClientImg4' src={process.env.PUBLIC_URL+'./Clients/County.png'}/>
              <img className='ourClientImg5' src={process.env.PUBLIC_URL+'./Clients/housr.png'}/>
              
              </div>
              </Marquee>
           {/* </marquee> */}
        </div>
    </div>
    

  )
}
