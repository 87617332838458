import taj from "../../assets/Video/Taj.mp4";
import main from "../../assets/Video/WebsiteVideo.mp4";

export const bannerData = [
  {
    id: 2,
    img: "./carousel/carousel1.webp",
    play: true,
    link: main,
  },
  {
    id: 3,
    img: "./carousel/carousel3.webp",
    play: false,
    link: "4y3VGRWqPxI",
  },
  {
    id: 4,
    img: "./carousel/carousel4.webp",
    play: false,
    link: "4y3VGRWqPxI",
  },
  {
    id: 5,
    img: "./carousel/carousel5.webp",
    play: false,
    link: "4y3VGRWqPxI",
  },
  {
    id: 6,
    img: "./carousel/carousel6.webp",
    play: true,
    link: taj,
  },
  {
    id: 7,
    img: "./carousel/carousel7.webp",
    play: false,
    link: "4y3VGRWqPxI",
  },
  {
    id: 8,
    img: "./carousel/carousel8.webp",
    play: false,
    link: "4y3VGRWqPxI",
  },
];

export const creativeData = [
  {
    id: 6,
    img: "./Creatives/Creative6.webp",
    play: false,
    link: "4y3VGRWqPxI",
  },

  {
    id: 2,
    img: "./Creatives/Creative2.webp",
    play: false,
    link: "4y3VGRWqPxI",
  },
  {
    id: 3,
    img: "./Creatives/Creative3.webp",
    play: false,
    link: "4y3VGRWqPxI",
  },
  {
    id: 4,
    img: "./Creatives/Creative4.webp",
    play: false,
    link: "4y3VGRWqPxI",
  },
  {
    id: 5,
    img: "./Creatives/Creative5.webp",
    play: true,
    link: "l2GWc8zYSDI",
  },
  {
    id: 1,
    img: "./Creatives/Creative1.webp",
    play: false,
    link: "4y3VGRWqPxI",
  },
];

export const gptData = [
  {
    id: 2,
    img: "./carousel/carousel1.webp",
    play: true,
    link: main,
  },
  {
    id: 3,
    img: "./carousel/carousel3.webp",
    play: false,
    link: "4y3VGRWqPxI",
  },
  {
    id: 4,
    img: "./carousel/carousel4.webp",
    play: false,
    link: "4y3VGRWqPxI",
  },
  {
    id: 5,
    img: "./carousel/carousel5.webp",
    play: false,
    link: "4y3VGRWqPxI",
  },
  {
    id: 6,
    img: "./carousel/carousel6.webp",
    play: true,
    link: taj,
  },
  {
    id: 7,
    img: "./carousel/carousel7.webp",
    play: false,
    link: "4y3VGRWqPxI",
  },
  {
    id: 8,
    img: "./carousel/carousel8.webp",
    play: false,
    link: "4y3VGRWqPxI",
  },
];
