import React from 'react'
import './Banner.css'
export default function Banner() {
  return (
    <div className='banner-container'>
        <div className='single-banner-container'>
            <img className='img-banner1' src={process.env.PUBLIC_URL+'./gpay/Icon1.svg'} alt="Banner1"/>
            <div className='banner-text'>Control<br/>from anywhere</div>
        </div>
        <div className='banner-divide-line'></div>
        <div className='single-banner-container'>
            <img className='img-banner2' src={process.env.PUBLIC_URL+'./gpay/Icon2.svg'} alt="Banner1"/>
            <div className='banner-text'>Life Time<br/>Warranty</div>
        </div>
        <div className='banner-divide-line'></div>
        <div className='single-banner-container'>
            <img className='img-banner3' src={process.env.PUBLIC_URL+'./gpay/Icon3.svg'} alt="Banner1"/>
            <div className='banner-text'>Free Shipping<br/>In India</div>
        </div>
        <div className='banner-divide-line'></div>
        <div className='single-banner-container'>
            <img className='img-banner4' src={process.env.PUBLIC_URL+'./gpay/Icon4.png'} alt="Banner1"/>
            <div className='banner-text'>No rewiring<br/>required</div>
        </div>
    </div>
  )
}
