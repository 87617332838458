export const mdata = [
  {
    planName: "1 Room Plan",
    appliances: "4",
  },
  {
    planName: "1 BHK Plan",
    appliances: "8",
  },
  {
    planName: "2 BHK Plan",
    appliances: "12",
  },
  {
    planName: "3 BHK Plan",
    appliances: "18",
  },
  {
    planName: "4 BHK Plan",
    appliances: "22",
  },
];

export const fixedAppliances = {
  room1: 4,
  bhk1: 8,
  bhk2: 12,
  bhk3: 18,
  bhk4: 22,
};

export const PlanCost = [
  {
    planName: "Monthly",
    cost: 2,
    days: 60,
  },
  {
    planName: "Quarterly",
    cost: 1,
    days: 90,
  },
  {
    planName: "Semi Annualy",
    cost: 1,
    days: 180,
  },
  {
    planName: "Annualy",
    cost: 1,
    days: 365,
  },
];
