import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./component/Mobile/Main";
import Confirmation2 from "./component/summary_page/Confirmation2";
import Confirmation from "./component/summary_page/Confirmation";
import MobileSummary from "./component/Mobile/MobileSummary";
import Confirmation3 from "./component/summary_page/Receipt";
import "./App.css";
import PartialAndCompletRecipt from "./component/summary_page/PartialAndCompletRecipt";
import VocherRecipt from "./component/summary_page/VocherRecipt";
import  { Toaster } from 'react-hot-toast';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/:phone" element={<Main />} />
        <Route path="/summary" element={<MobileSummary />} />
        <Route
          path="/confirm/:generatedHex"
          element={<PartialAndCompletRecipt />}
        />
        <Route
          path="/voucher/:generatedHex"
          element={<VocherRecipt />}
        />
        <Route path="/receipt/:generatedHex" element={<Confirmation3 />} />

        <Route path="/payment/:generatedHex" element={<Confirmation2 />} />
        <Route
          path="/:security/:installation/:planId/:planFrequency/:totalAplliances"
          element={<Confirmation />}
        />
      </Routes>
      <Toaster/>
    </BrowserRouter>
  );
}
