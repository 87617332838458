import React, { useEffect, useState, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./Slider.css";
import { bannerData } from "../Data/ImageData";
import { carouselOpened } from "../../utils/fb";

export default function Slider() {
  const videoParentRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    for (let i = 0; i < bannerData.length; i++) {
      if (document.getElementById(i) !== null) {
        document.getElementById(i).pause();
      }
    }
    if (document.getElementById(currentSlide) !== null) {
      document.getElementById(currentSlide).play();
    }
    carouselOpened({_id: currentSlide});
  }, [currentSlide]);

  return (
    <div>
      <Carousel
        selectedItem={currentSlide}
        showThumbs={false}
        onChange={(index) => {
          console.log(index);
          if (currentSlide !== index) {
            setCurrentSlide(index);
          }
        }}
        swipe={true}
        className="slider-container"
        emulateTouch={true}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={20}
      >
        {bannerData.map((e, i) => {
          return (
            <div className="slider-img" key={e.id}>
              {e.play === true ? (
                <video
                  ref={videoParentRef}
                  data-wf-ignore="true"
                  data-object-fit="cover"
                  controls="controls"
                  id={i}
                  playsInline
                  autoPlay=""
                  loop
                  muted="muted"
                  controlsList="nodownload nofullscreen"
                  disablePictureInPicture
                  disableRemotePlayback
                  className="video"
                >
                  <source type="video/mp4" src={e.link}></source>
                </video>
              ) : (
                <img src={process.env.PUBLIC_URL + e.img} />
              )}
            </div>
          );
        })}
      </Carousel>
      <div className="tumb-container">
        {bannerData.map((ele, index) => {
          return (
            <div
              className="single-tumb-container"
              style={{
                border: currentSlide === index ? "1px solid blue" : "none",
              }}
              onClick={() => setCurrentSlide(index)}
            >
              <img src={ele.img} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
