export const sData = [
  { value: "Plug and Play" },
  { value: "24*7 Real-Time Monitoring" },
  { value: "Power Suooly Control" },
  { value: "Set Schedules and Timers" },
  { value: "Global Remote Access" },
  { value: "Voice Control" },
  { value: "Wi-Fi Enabled" },
];

export const fData = [
  { left: "Wi-Fi Enabled", right: "Yes, 2.4Ghz" },
  { left: "Voltage", right: "220 - 250V AC" },
  { left: "Max Load", right: "10A" },
  { left: "Compatible OS", right: "Android/iOS" },
  { left: "Voice Control", right: "Alexa & Google Assistant" },
  { left: "Body Material", right: "Fire Retardant Plastic" },
  { left: "Warranty", right: "Lifetime" },
  { left: "Country of Origin", right: "Made in India" },
];
