import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Main.css";
import { fixedAppliances } from "./MobileData";
import { serverUrl } from "../../ApiUrl";
import { mobileViewPlanDetail } from "../redux/action";
import { useDispatch, useSelector } from "react-redux";
import MobileSummary from "./MobileSummary";
import Slider from "../Courosel/Slider";
import Gpay from "../small Component/Gpay";
import Banner from "../small Component/Banner";
import Creatives from "../small Component/Creatives";
import Feature from "../small Component/Feature";

import {
  CallToKnowMore,
  faqOpened,
  packageOpened,
  pageView,
  PhoneClicked,
  reviewOpened,
  SMFacebook,
  SMInstagram,
  SMLinkedIn,
  SMWhatsApp,
  WhatsAppClicked,
} from "../../utils/fb";
import { useParams } from "react-router-dom";
import YouTube from "react-youtube";
import moment from "moment";
import { faqDataGet } from "../ApiCalls/getFaqData";
import { reviewDataGet } from "../ApiCalls/getReviewData";
import OurClients from "../small Component/OurClients";
const packages = {
  "1Room": {
    packageId: 1,
    packageName: "1 Room",
    appliances: 4,
  },
  "1BHK": {
    packageId: 2,
    packageName: "1 BHK",
    appliances: 8,
  },
  "2BHK": {
    packageId: 3,
    packageName: "2 BHK",
    appliances: 12,
  },
  "3BHK": {
    packageId: 4,
    packageName: "3 BHK",
    appliances: 18,
  },
  "4BHK": {
    packageId: 5,
    packageName: "4 BHK",
    appliances: 22,
  },
};
export default function Main() {
  const dispatch = useDispatch();
  let { phone } = useParams();
  let [links, setLinks] = useState("");
  let data = useSelector((state) => state.mobilePlanData);
  let faqData = useSelector((state) => state.faqData);
  let reviewData = useSelector((state) => state.reviewData);
  let [radios, setRadio] = useState({ ...data, refferal: phone });
  useEffect(() => {
    dispatch(mobileViewPlanDetail(radios));
  }, [radios, dispatch]);

  useEffect(() => {
    pageView({ name: "Select Package", location: window.location.toString() });
    faqDataGet(dispatch);
    reviewDataGet(dispatch);
  }, []);
  let urlParams = new URLSearchParams(window.location.search);
  let utm = {
    source: urlParams.get("utm_source") || "",
    campaign: urlParams.get("utm_campaign") || "",
    medium: urlParams.get("utm_medium") || "",
  };
  let utmQuery = "";
  if (utm.source !== "") {
    utmQuery = `?utm_source=${utm.source}&utm_medium=${utm.medium}&utm_campaign=${utm.campaign}`;
  }
  const opts = {
    height: "500",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  function onReady(event) {
    event.target.pauseVideo();
  }
  return (
    <div className="main-background">
      <div className="main_Discount_offer_top_header">
        <div className="main_Discount_offer_top_header_upto">
          Cashback upto ₹500
        </div>
        <div className="main_Discount_offer_top_header_1500">
          on min purchase of ₹1,500 via Mobikwik
        </div>
      </div>
      <div className="Header_container">
        <div className="logo_Contaniner">
          <img
            src={process.env.PUBLIC_URL + "/MobileAssets/logo.png"}
            alt="logo"
          />
        </div>
        <div className="CompanyName">Aliste</div>
        <div className="phone-what-container">
          <div className="phone_Contaniner">
            <a
              href={`tel:+918160247293`}
              target="_blank"
              style={{ textDecoration: "none", color: "white" }}
              onClick={PhoneClicked}
            >
              <img
                src={process.env.PUBLIC_URL + "/MobileAssets/PhoneNav.png"}
                alt="logo"
              />
            </a>
          </div>
          <div className="whatapp_Contaniner">
            <a
              href={`http://wa.me/+919664861809?text=Hi`}
              target="_blank"
              onClick={WhatsAppClicked}
              style={{ textDecoration: "none", color: "white" }}
            >
              <img
                src={process.env.PUBLIC_URL + "/MobileAssets/Whatapp.png"}
                alt="logo"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="main-header-hr"></div>
      <div className="wab-container-top">
        <div className="wab-container-top-slider">
          <Slider />
        </div>

        <div className="wab-container-top-other">
          <Gpay />
          <div className="Automation_text_container">
            <div className="Automation_text">
              Smart Home at <strong className="Pay300">₹ 1</strong>
              <span className="automation-slashDay">/day</span>
            </div>
            <div className="Automation_subtext">
              Aliste brings you the coolest smart home tech via subscription
              plans that won't strain your wallet. Experience the future of home
              automation without breaking the bank.{" "}
            </div>
          </div>
          <a
            href={
              radios.hasOwnProperty("rate")
                ? `/summary${utmQuery ? utmQuery : ""}`
                : `/#plan${utmQuery ? utmQuery : ""}`
            }
            style={{ cursor: "pointer", textDecoration: "none" }}
            className="phone"
          >
            <div className="pay_for_intallation">
              <strong style={{ marginRight: "4px" }} className="Pay300">
                Pay ₹ 300
              </strong>
              to schedule your installation
            </div>
          </a>
          <a
            href={`/#plan${utmQuery ? utmQuery : ""}`}
            style={{ textDecoration: "none" }}
            className="desktop"
          >
            <div className="pay_for_intallation">
              <strong style={{ marginRight: "4px" }}>Pay ₹ 300</strong>to
              schedule your installation
            </div>
          </a>
          <div className="Secured_by_container">
            <span className="Secured_by">Secured by</span>
            <img src={process.env.PUBLIC_URL + "./gpay/Razorpay.png"} />
          </div>
          <div className="mobiKwik_container">
            Get upto ₹ 150 cashback on payment using{" "}
            <img src={process.env.PUBLIC_URL + "./gpay/mobi.png"} /> MobiKwik
          </div>
          <Banner />
        </div>
      </div>
      <br />
      <div className="main-selectPlan-text-m" id="plan">
        Select <span>a Plan</span>
      </div>
      <div className="main-selectPlan-text-d" id="plan">
        <div>
          Select <span>a Plan and</span> Pay
        </div>
      </div>
      <div className="both-screen">
        <div className="main-mainScreen">
          <div
            className="main-radio-with-description"
            style={{ border: radios.Room1Plan ? "1px solid #2050FF" : "" }}
            onClick={() => {
              packageOpened(packages["1Room"]);
              setRadio({
                Room1Plan: true,
                Bhk1Plan: false,
                Bhk2Plan: false,
                Bhk3Plan: false,
                Bhk4Plan: false,
                appliances: fixedAppliances.room1,
                rate: fixedAppliances.room1 * 30,
                planName: "1 Room Plan",
                planId: 1,
              });
            }}
          >
            <div className="Main-1Room">
              <div className="main-radio-firstPart">
                <input type="radio" name="plan" checked={radios.Room1Plan} />
                <div className="main-radio-text">
                  <div className="main-radio-text-heading">1 Room Plan</div>
                  <div className="main-radio-text-subheading">
                    Automate {fixedAppliances.room1} appliances
                  </div>
                </div>
              </div>
              <div className="main-radio-price">
                ₹ {fixedAppliances.room1 * 1}/day
              </div>
            </div>
            <div
              className="main-list-container"
              style={{ display: radios.Room1Plan ? "" : "none" }}
            >
              <div className="main-list">
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/MobileAssets/correct.png"}
                    alt={"correct"}
                  />
                </div>
                <div className="main-list-text">
                  Automate your lights, fans, geysers, TVs , ACs & more
                </div>
              </div>
              <div className="main-list">
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/MobileAssets/correct.png"}
                    alt={"correct"}
                  />
                </div>
                <div className="main-list-text">
                  Entails a refundable security deposit of ₹{" "}
                  {fixedAppliances.room1 * 100} & an installation cost of ₹ 300
                </div>
              </div>
            </div>
          </div>

          {/* 1Bhk */}
          <div
            className="main-radio-with-description"
            style={{ border: radios.Bhk1Plan ? "1px solid #2050FF" : "" }}
            onClick={() => {
              packageOpened(packages["1BHK"]);
              setRadio({
                Room1Plan: false,
                Bhk1Plan: true,
                Bhk2Plan: false,
                Bhk3Plan: false,
                Bhk4Plan: false,
                appliances: fixedAppliances.bhk1,
                rate: fixedAppliances.bhk1 * 30,
                planName: "1 BHK Plan",
                planId: 2,
              });
            }}
          >
            <div className="Main-1Room">
              <div className="main-radio-firstPart">
                <input type="radio" name="plan" checked={radios.Bhk1Plan} />
                <div className="main-radio-text">
                  <div className="main-radio-text-heading">1 BHK Plan</div>
                  <div className="main-radio-text-subheading">
                    Automate {fixedAppliances.bhk1} appliances
                  </div>
                </div>
              </div>
              <div className="main-radio-price">
                ₹ {fixedAppliances.bhk1 * 1}/day
              </div>
            </div>
            <div
              className="main-list-container"
              style={{ display: radios.Bhk1Plan ? "" : "none" }}
            >
              <div className="main-list">
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/MobileAssets/correct.png"}
                    alt={"correct"}
                  />
                </div>
                <div className="main-list-text">
                  Automate your lights, fans, geysers, TVs , ACs & more
                </div>
              </div>
              <div className="main-list">
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/MobileAssets/correct.png"}
                    alt={"correct"}
                  />
                </div>
                <div className="main-list-text">
                  Entails a refundable security deposit of ₹{" "}
                  {fixedAppliances.bhk1 * 100} & an installation cost of ₹ 300
                </div>
              </div>
            </div>
          </div>

          {/* 2Bhk */}
          <div
            className="main-radio-with-description"
            style={{ border: radios.Bhk2Plan ? "1px solid #2050FF" : "" }}
            onClick={() => {
              packageOpened(packages["2BHK"]);
              setRadio({
                Room1Plan: false,
                Bhk1Plan: false,
                Bhk2Plan: true,
                Bhk3Plan: false,
                Bhk4Plan: false,
                appliances: fixedAppliances.bhk2,
                rate: fixedAppliances.bhk2 * 30,
                planName: "2 BHK Plan",
                planId: 3,
              });
            }}
          >
            <div className="Main-1Room">
              <div className="main-radio-firstPart">
                <input type="radio" name="plan" checked={radios.Bhk2Plan} />
                <div className="main-radio-text">
                  <div className="main-radio-text-heading">2 BHK Plan</div>
                  <div className="main-radio-text-subheading">
                    Automate {fixedAppliances.bhk2} appliances
                  </div>
                </div>
              </div>
              <div className="main-radio-price">
                ₹ {fixedAppliances.bhk2 * 1}/day
              </div>
            </div>
            <div
              className="main-list-container"
              style={{ display: radios.Bhk2Plan ? "" : "none" }}
            >
              <div className="main-list">
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/MobileAssets/correct.png"}
                    alt={"correct"}
                  />
                </div>
                <div className="main-list-text">
                  Automate your lights, fans, geysers, TVs , ACs & more
                </div>
              </div>
              <div className="main-list">
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/MobileAssets/correct.png"}
                    alt={"correct"}
                  />
                </div>
                <div className="main-list-text">
                  Entails a refundable security deposit of ₹{" "}
                  {fixedAppliances.bhk2 * 100} & an installation cost of ₹ 340
                </div>
              </div>
            </div>
          </div>

          {/* 3Bhk */}
          <div
            className="main-radio-with-description"
            style={{ border: radios.Bhk3Plan ? "1px solid #2050FF" : "" }}
            onClick={() => {
              packageOpened(packages["3BHK"]);
              setRadio({
                Room1Plan: false,
                Bhk1Plan: false,
                Bhk2Plan: false,
                Bhk3Plan: true,
                Bhk4Plan: false,
                appliances: fixedAppliances.bhk3,
                rate: fixedAppliances.bhk3 * 30,
                planName: "3 BHK Plan",
                planId: 4,
              });
            }}
          >
            <div className="Main-1Room">
              <div className="main-radio-firstPart">
                <input type="radio" name="plan" checked={radios.Bhk3Plan} />
                <div className="main-radio-text">
                  <div className="main-radio-text-heading">3 BHK Plan</div>
                  <div className="main-radio-text-subheading">
                    Automate {fixedAppliances.bhk3} appliances
                  </div>
                </div>
              </div>
              <div className="main-radio-price">
                ₹ {fixedAppliances.bhk3 * 1}/day
              </div>
            </div>
            <div
              className="main-list-container"
              style={{ display: radios.Bhk3Plan ? "" : "none" }}
            >
              <div className="main-list">
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/MobileAssets/correct.png"}
                    alt={"correct"}
                  />
                </div>
                <div className="main-list-text">
                  Automate your lights, fans, geysers, TVs , ACs & more
                </div>
              </div>
              <div className="main-list">
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/MobileAssets/correct.png"}
                    alt={"correct"}
                  />
                </div>
                <div className="main-list-text">
                  Entails a refundable security deposit of ₹{" "}
                  {fixedAppliances.bhk3 * 100} & an installation cost of ₹ 460
                </div>
              </div>
            </div>
          </div>

          {/* 4Bhk */}
          <div
            className="main-radio-with-description"
            style={{
              border: radios.Bhk4Plan ? "1px solid #2050FF" : "",
              marginBottom: "0px",
            }}
            onClick={() => {
              packageOpened(packages["4BHK"]);
              setRadio({
                Room1Plan: false,
                Bhk1Plan: false,
                Bhk2Plan: false,
                Bhk3Plan: false,
                Bhk4Plan: true,
                appliances: fixedAppliances.bhk4,
                rate: fixedAppliances.bhk4 * 30,
                planName: "4 BHK Plan",
                planId: 5,
              });
            }}
          >
            <div className="Main-1Room">
              <div className="main-radio-firstPart">
                <input type="radio" name="plan" checked={radios.Bhk4Plan} />
                <div className="main-radio-text">
                  <div className="main-radio-text-heading">4 BHK Plan</div>
                  <div className="main-radio-text-subheading">
                    Automate {fixedAppliances.bhk4} appliances
                  </div>
                </div>
              </div>
              <div className="main-radio-price">
                ₹ {fixedAppliances.bhk4 * 1}/day
              </div>
            </div>
            <div
              className="main-list-container"
              style={{ display: radios.Bhk4Plan ? "" : "none" }}
            >
              <div className="main-list">
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/MobileAssets/correct.png"}
                    alt={"correct"}
                  />
                </div>
                <div className="main-list-text">
                  Automate your lights, fans, geysers, TVs , ACs & more
                </div>
              </div>
              <div className="main-list">
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/MobileAssets/correct.png"}
                    alt={"correct"}
                  />
                </div>
                <div className="main-list-text">
                  Entails a refundable security deposit of ₹{" "}
                  {fixedAppliances.bhk4 * 100} & an installation cost of ₹ 540
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-mobile-Summary">
          {Object.keys(radios).length !== 0 ? (
            <MobileSummary />
          ) : (
            <div>
              <div className="main-plan-notSeleted-text-subHead">
                No plan <span style={{ fontWeight: "800" }}>Selected</span>
              </div>
              <div className="main-plan-notSeleted-text-subHeading">
                Select a plan to know more
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Pay Button */}
      {Object.keys(radios).length !== 0 && radios?.appliances !== undefined ? (
        <div className="main-payButton-container">
          <div className="main-payButton-value-container">
            <div className="main-payButton-rate">
              ₹ {radios?.appliances}/day
            </div>
            <div className="main-payButton-security">
              + {radios?.appliances * 100} Security charges
            </div>
          </div>
          <Link
            to={`/summary${utmQuery ? utmQuery : ""}`}
            style={{ textDecoration: "none" }}
          >
            <div
              className="main-payButton-btn-container"
              onClick={() => dispatch(mobileViewPlanDetail(radios))}
            >
              <div className="main-payButton-btn-text">Review Plan</div>
            </div>
          </Link>
        </div>
      ) : null}
      <OurClients />
      <Creatives />
      <div className="bottom-mobile-main">
        <FAQs faqData={faqData} setLinks={setLinks} faqOpened={faqOpened} />
        <Reviews
          reviewData={reviewData}
          reviewOpened={reviewOpened}
          setLinks={setLinks}
        />
        <WriteReview />
        <YoutubePlayer
          links={links}
          opts={opts}
          onReady={onReady}
          setLinks={setLinks}
        />
      </div>

      <div className="main-faq-heading-container phone">
        <span>Device </span>
        <span style={{ fontWeight: "800" }}>specifications</span>
      </div>
      <Feature />
      <Footer utmQuery={utmQuery} />
    </div>
  );
}

const FAQs = ({ faqData, setLinks, faqOpened }) => (
  <>
    <div className="main-faq-heading-container">
      <span>Frequently asked </span>
      <span style={{ fontWeight: "800" }}>Questions</span>
    </div>
    <div className="main-faq-review">
      {faqData.map((e) => {
        if (e.hide !== true) {
          return (
            <div
              className="main-faq-video"
              onClick={() => {
                setLinks(e.link);
                faqOpened(e);
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="main-faq-img">
                <img src={serverUrl.u + e.imageLink} />
              </div>
              <div style={{ color: "white" }} className="main-faq-rightSide">
                <div className="head">{e.header}</div>
                <div className="subHead">{e.subheader}</div>
              </div>
            </div>
          );
        }
      })}
    </div>
  </>
);
const Reviews = ({ reviewData, setLinks, reviewOpened }) => (
  <>
    <div className="main-review-heading-container">
      <span>Our </span>
      <span style={{ fontWeight: "800" }}>Reviews</span>
    </div>
    <div className="main-faq-review">
      {reviewData.map((e) => {
        return (
          <div
            className="main-review-video"
            onClick={() => {
              setLinks(e.videoId);
              reviewOpened(e);
            }}
            style={{ cursor: "pointer" }}
          >
            <div style={{ color: "white" }} className="main-review-rightSide">
              <div className="head">
                {e.reviewName}
                <img src={serverUrl.u + e.reviewStatusUserImage} />
              </div>
              <div className="date">
                {moment(e.reviewDate).format("DD MM YYYY")}
              </div>
              <div className="main-review-star">
                {Array.from({ length: e.reviewRating }).map((ele) => {
                  return (
                    <img
                      src={process.env.PUBLIC_URL + "./MobileAssets/Vector.png"}
                      alt="star"
                    />
                  );
                })}
              </div>
              <div className="subHead">{e.reviewText}</div>
            </div>
            <div className="main-review-img">
              <img src={serverUrl.u + e.reviewImage} />
            </div>
          </div>
        );
      })}
    </div>
  </>
);

const YoutubePlayer = ({ links, opts, onReady, setLinks }) =>
  links !== "" ? (
    <div
      style={{
        position: "fixed",
        top: "10%",
        width: "95%",
        marginLeft: "2.5%",
      }}
    >
      <div
        style={{
          color: "white",
          float: "right",
          marginBottom: "5px",
          padding: "3px 8px",
          fontSize: "22px",
          borderRadius: "50%",
          fontFamily: "Manrope",
          cursor: "pointer",
        }}
        onClick={() => setLinks("")}
      >
        x
      </div>
      <YouTube videoId={links} opts={opts} onReady={onReady} />
    </div>
  ) : null;

const WriteReview = () => (
  <div className="main-write-review">
    {" "}
    <a
      href={`http://wa.me/+919664861809?text=Hi`}
      target="_blank"
      style={{ textDecoration: "none", color: "white" }}
    >
      Write a review
    </a>
  </div>
);

const Footer = ({ utmQuery }) => {
  return (
    <div className="bottom-wab-main">
      <div className="container-large">
        <img
          src={process.env.PUBLIC_URL + "/DesktopAssets/Aliste_Logo.webp"}
          loading="lazy"
          alt=""
          className="footer-logo"
        />
        <div className="main-footer-text">
          Elevate the comfort of your home. Aliste Helps you make your home
          smart without making you burn a hole through your pocket.Our aim is to
          redefine the way people live by imparting luxury and maximising
          convenience through our products. Our Smart Home product line and
          mobile applications have been lauded by our users and industry -
          experts. Join us in making the Indian home, a more secure &amp;
          comfortable place to live.
        </div>
        <div className="footer-main-container">
          <div className="footer-main-left-Side">
            <div className="footer-column footer-row">
              <div className="footer-title-light">
                Buy now and get the best smart home solution
              </div>
              <a
                href="tel:+918160247293"
                className="button-sign-up-2-copy-copy w-button"
                onClick={CallToKnowMore}
              >
                <strong className="bold-text-17">Call us</strong>&nbsp;to know
                more
              </a>
              <div className="main-logo-socials">
                <a
                  href="https://www.facebook.com/alistetechnologies/"
                  className="social-link w-inline-block"
                  onClick={SMFacebook}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/DesktopAssets/faceBook.svg"}
                    loading="lazy"
                    alt="Facebook"
                    className="sm-icon"
                  />
                </a>
                <a
                  href="https://www.instagram.com/alistetechnologies/"
                  className="social-link w-inline-block"
                  onClick={SMInstagram}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/DesktopAssets/Instagram.svg"
                    }
                    loading="lazy"
                    alt="Instagram"
                    className="sm-icon"
                  />
                </a>
                <a
                  id="w-node-cc26770a-4e6f-8d0f-5b3f-32ee26744627-26744611"
                  href="https://www.linkedin.com/company/alistetechnologies"
                  className="social-link w-inline-block"
                  onClick={SMLinkedIn}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/DesktopAssets/LinkedIn.svg"}
                    loading="lazy"
                    alt="Linkedin"
                    className="sm-icon"
                  />
                </a>
                <a
                  id="w-node-cc26770a-4e6f-8d0f-5b3f-32ee26744629-26744611"
                  href="https://wa.me/+919664861809"
                  className="social-link w-inline-block"
                  onClick={SMWhatsApp}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/DesktopAssets/what.svg"}
                    loading="lazy"
                    alt="WhatsApp"
                    height="Auto"
                    className="sm-icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="main-footer-right-Side">
            <div
              id="w-node-cc26770a-4e6f-8d0f-5b3f-32ee2674462c-26744611"
              className="footer-column"
            >
              <div className="footer-title-light">Company</div>
              <a
                href={`https://www.alistetechnologies.com/privacy-policy${
                  utmQuery ? utmQuery : ""
                }`}
                className="footer-text-link"
              >
                Privacy Policy
              </a>
              <a
                href={`https://www.alistetechnologies.com/user-agreement${
                  utmQuery ? utmQuery : ""
                }`}
                className="footer-text-link"
              >
                User Agreement
              </a>
              <a
                href={`https://www.alistetechnologies.com/subscriptionterms${
                  utmQuery ? utmQuery : ""
                }`}
                className="footer-text-link"
              >
                Terms of Use
              </a>
            </div>
            <div className="footer-column">
              <div className="footer-title-light">Product</div>
              <a
                href={`https://www.alistetechnologies.com/our-app${
                  utmQuery ? utmQuery : ""
                }`}
                className="footer-text-link"
              >
                Our App
              </a>
              <a
                href={`https://www.alistetechnologies.com/${
                  utmQuery ? utmQuery : ""
                }#devices`}
                className="footer-text-link"
              >
                Our Devices
              </a>
              <a
                href={`https://www.alistetechnologies.com/${
                  utmQuery ? utmQuery : ""
                }#pricing`}
                className="footer-text-link"
              >
                Our Pricing
              </a>
            </div>
            <div className="footer-column">
              <div className="footer-title-light">Marketing</div>
              <a
                href={`https://www.alistetechnologies.com/${
                  utmQuery ? utmQuery : ""
                }#clients`}
                className="footer-text-link"
              >
                Our Clients
              </a>
              <a
                href={`https://www.alistetechnologies.com/${
                  utmQuery ? utmQuery : ""
                }#investor`}
                className="footer-text-link"
              >
                Our Investors
              </a>
              <a
                href={`https://www.alistetechnologies.com/${
                  utmQuery ? utmQuery : ""
                }#FAQ`}
                className="footer-text-link"
              >
                Our FAQ
              </a>
            </div>
          </div>
        </div>
        <div className="footer-title-light-copy">
          Copyright © Aliste since 2018. All rights reserved.
        </div>
      </div>
    </div>
  );
};
