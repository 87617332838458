export const userResposeDataReducer = (state = {}, action) => {
  switch (action.type) {
    case "userResponseInfo":
      return { ...action.payload };
    default:
      return state;
  }
};

export const userPlanNameReducer = (state = {}, action) => {
  switch (action.type) {
    case "userPlan":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const completeDataReducer = (state = {}, action) => {
  switch (action.type) {
    case "completeData":
      return { ...action.payload };
    case "updateCompleteData":
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

// mobile view
export const mobileViewPlanDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case "mobileViewPlan":
      return action.payload;
    default:
      return state;
  }
};

export const getFaqReducer = (state = [], action) => {
  switch (action.type) {
    case "faqDataGet":
      return action.payload;
    default:
      return state;
  }
};

export const getReviewReducer = (state = [], action) => {
  switch (action.type) {
    case "reviewDataGet":
      return action.payload;
    default:
      return state;
  }
};
