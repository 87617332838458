import React from 'react'
import './Gpay.css'
export default function Gpay() {
  return (
    <div>
        <div className='gpay-container'>
            <div className='gpay-container_rating'>
           
            <span className='gpay-rating'>4.8/5</span>
            <div className='gpay-star-container'>
            {
                  Array.from({length:5}).map((ele,index)=>{
                    return(
                      <img src={process.env.PUBLIC_URL+'./gpay/star.png' }alt="star" key={index}/>
                    )
                  })
                }
                 </div>
            </div>
            <div>
            <span style={{color:"#ffffff7a"}}> - </span>
            </div>
         <div  className='gpay-right'>
            <div className='gpay-logo-text'>
            <img src={process.env.PUBLIC_URL+'./gpay/g.png'} className='gpayimg'/>
            <span className='review'>Review</span>
            </div>
            <span className='customer'>(10,000+ Customers)</span>
            </div>
        </div>
    </div>
  )
}
