
import axios from "axios";
import { faqDataAction } from "../redux/action";
import { serverUrl } from "../../ApiUrl";

export const faqDataGet = (dispatch) =>{
    axios.get(`${serverUrl.u}/api/faq/getFaq`).then(
        function (response) {
            const {message,data,success} = response.data
            dispatch(faqDataAction(data))
        }
    ).catch(function (error) {
        console.log(error);
    })
}