import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./MobileSummary.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { dummy } from "./Data";

import { ToastContainer } from "react-toastify";
import { CaretDown } from "react-bootstrap-icons";

import "react-toastify/dist/ReactToastify.css";
import { initiateCheckout, pageView, trackContact } from "../../utils/fb";
import { PlanCost } from "./MobileData";
import { useRef } from "react";
import {
  consumeVocherCodeApi,
  getVoucherStatusApi,
  registerVoucherClient,
} from "../ApiCalls/getVoucherStatus";
import Captcha from "./Captcha";
import { serverUrl } from "../../ApiUrl";

const sendUserData = async (number, email, data) => {
  await axios
    .post(`${serverUrl.u}/api/ecom/createTemporaryCart`, {
      phone: number,
      name: "No",
      page: "payment page",
      email: email,
      city: "Any For Now",
      plan: data.planId,
    })
    .then(function () {})
    .catch(function () {});
};

export default function MobileSummary() {
  let data = useSelector((state) => state.mobilePlanData);
  const randomString = Math.random().toString(36).slice(8)
   const [captcha,setCaptcha] =  useState(randomString)
   const [captchaEnter,setCaptchaEnter] =  useState("")
  let [installation, setInsallation] = useState(300);
  let [wabLoad, setWabLoad] = useState(false);
  let [number, setNumber] = useState("");
  let [emailError, setEmailError] = useState(false);
  let [captchaError, setCaptchaError] = useState(false);
  let [phoneError, setPhoneError] = useState(false);
  let [botherrorE, setBothErrorE] = useState(false);
  let [botherrorP, setBothErrorP] = useState(false);
  let [applyBtn, setApplyBtn] = useState("Apply");
  let [voucherError, setVoucherError] = useState("");
  let [voucherConsumeError, setVoucherConsumeError] = useState("");
  let [voucherData, setVoucherData] = useState({});
  let [voucherDiscount, setVoucherDiscount] = useState("");
  let [voucherDisclamair,setVoucherDisclamair] = useState("")
  let [selectPopMobile, setSelectPopMobile] = useState(false);
  let [defaultSelectedPlan, setDefaultSelectedPlan] = useState(PlanCost[0]);
  let [Discount, setDiscount] = useState(0);
  let voucherCode = useRef(null);
  let voucherCodePhone = useRef(null);
  let [email, setEmail] = useState("");
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  useEffect(() => {
    if (data.appliances <= 10) {
      setInsallation(300);
    } else if (data.appliances > 10) {
      let app = data.appliances - 10;
      setInsallation(300 + app * 20);
    }
  }, [data]);

  useEffect(() => {
    pageView({ name: "Summary", location: window.location.toString() });
  }, []);

  useEffect(() => {
    /**this is wrong akjil it will call send user data again and again */
    if (botherrorE === true && botherrorP === true) {
      sendUserData(number, email, data);
    } else {
      return;
    }
  }, [botherrorP, botherrorE]);

  const registerLeadAndLink = async () => {
    let res = handleOnChange(email);
    if (res === false) return;
    if (number.length <= 9) {
      setPhoneError(true);
      return;
    }
    setWabLoad(true);
    let name = email.split("@");
    await axios
      .post(`${serverUrl.u}/api/zoho/registerLeadAndLink`, {
        planFrequency: defaultSelectedPlan.planName,
        planId: data.planId,
        email: email,
        refferal: data.refferal,
        teleCallerId: "website",
        first_name: name[0],
        last_name: " ",
        phone: number,
        city: "Any For Now",
        installation: installation,
        security: data.appliances * 100,
        address: "No",
        layout: dummy[data.planId],
        leadQuality: "10",
        origin: "website",
        comments: " ",
        zip: "",
        partialPayment: true,
        partialAmount: 300,
        secondarySalesPerson: "empty",
        discount: 0,
        orignalRecursiveAmount: data?.appliances * defaultSelectedPlan.days,
        orignalTotalAmount: (
          (data?.appliances * defaultSelectedPlan.days + installation) * 1.18 +
          data?.appliances * 100
        ).toFixed(2),
        recursiveAmount: data?.appliances * defaultSelectedPlan.days,
        totalAmount: (
          (data?.appliances * defaultSelectedPlan.days + installation) * 1.18 +
          data?.appliances * 100
        ).toFixed(2),
        addOnAmount: 0,
        totalAppliances: data.appliances,
      })
      .then(function (response) {
        window.location = response.data.data.partialPaymentDetails.short_url;
        setTimeout(function () {
          setWabLoad(false);
        }, 2000);
      })
      .catch(function () {});
  };

  const checkNumber = (num) => {
    if (num.length < 10) {
      setPhoneError(true);
    } else if (num.length >= 10) {
      setBothErrorP(true);
      setPhoneError(false);
    }
  };

  const getVoucherStataus = async (code) => {
    if (code === null) return;
    setApplyBtn("processing...");
    setVoucherError("");
    setVoucherDiscount("");
    setVoucherData({});
    setVoucherConsumeError("");
    setDiscount(0);
    setVoucherDisclamair('')
    let res = await getVoucherStatusApi(code,Number((
      (data?.appliances * defaultSelectedPlan.days + installation) * 1.18 +
      data?.appliances * 100
    ).toFixed(2)));
    if (res.success === false) {
      setVoucherError(res.message);
      setApplyBtn("Apply");
      setDiscount(0);
    } else if (res.success === true) {
      setVoucherData({});
      if (res.data.Status === "CONSUMED") {
        setVoucherDiscount("");
        setVoucherData({});
        setVoucherError("Voucher already redeemed");
        setApplyBtn("Apply");
        setDiscount(0);
      }
      if (res.data.Status === "VALID") {
        setVoucherError("");
        setVoucherData(res.data);
        setVoucherDiscount(`Voucher applied - INR  ${res.data.Value}  off `);
        setVoucherDisclamair("If your final bill value is less than the voucher amount, the extra amount will be forfeited")
        setDiscount(Number(res.data.Value));
        setApplyBtn("Apply");
      }
      if (res.data.Status === "INVALID") {
        setVoucherDiscount("");
        setVoucherData({});
        setVoucherError("Voucher is invalid");
        setApplyBtn("Apply");
        setDiscount(0);
      }
      if (res.data.Status === "EXPIRED") {
        setVoucherDiscount("");
        setVoucherData({});
        setVoucherError("Voucher is Expired");
        setApplyBtn("Apply");
        setDiscount(0);
      }
    }
  };
  const consumeVocherCode = async () => {
    setVoucherConsumeError("");
    let res = handleOnChange(email);
    if (res === false) return;
    if (number.length <= 9) {
      setPhoneError(true);
      return;
    }
    console.log(voucherData, "code");
    let ress = await consumeVocherCodeApi(
      email,
      number,
      voucherData.VoucherNumber,
      Number((
        (data?.appliances * defaultSelectedPlan.days + installation) * 1.18 +
        data?.appliances * 100
      ).toFixed(2))
    );
    console.log(ress, "resss");
    if (ress.success !== undefined && ress.success === false) {
      setVoucherData({});
      setVoucherConsumeError(ress.message);
      setVoucherDiscount("");
      return;
    }
    if (
      ress.createResponse !== undefined &&
      ress.createResponse.success === false
    ) {
      setVoucherData({});
      setVoucherConsumeError(ress.createResponse.message);
      setVoucherDiscount("");
      return;
    }
    if (ress.createResponse.success === true) {
      setWabLoad(true);
      let name = email.split("@");
      let detail = {
        transactionId: ress.createResponse.data.voucherHistory.voucherCode,
        email: email,
        first_name: name[0],
        last_name: "",
        phone: number,
        city: "Any For Now",
        zone: "",
        address: "",
        zip: "",
        planId: data.planId,
        planFrequency: defaultSelectedPlan.planName,
        teleCallerId: "website",
        installation: installation,
        security: data.appliances * 100,
        partialPayment: true,
        partialAmount: voucherData.Value,
        discount: 0,
        recursiveAmount: data?.appliances * defaultSelectedPlan.days,
        totalAmount: (
          (data?.appliances * defaultSelectedPlan.days + installation) * 1.18 +
          data?.appliances * 100
        ).toFixed(2),
        addOnAmount: 0,
        totalAppliances: data?.appliances,
        ispSubscription: false,
      };
      await registerVoucherClient(detail);
      setWabLoad(false);
    }
  };

  let handleOnChange = (email) => {
    setEmail(email);

    if (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setEmailError(false);
      setBothErrorE(true);
      setEmail(email);
    } else {
      setEmailError(true);
      return false;
    }
  };
  useEffect(()=>{
    if(captcha===captchaEnter){
      setCaptchaError(false)
    }
  },[captchaEnter])
  useEffect(()=>{
    let emailtest=false
    let phonetest=false
    if (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      emailtest=true;
    } else {
      emailtest= false;
    }
    if (number.length < 10) {
      phonetest=false
    } else if (number.length === 10) {
      phonetest=true
    }else{
      phonetest=false
    }
    if(phonetest===true && emailtest===true){
      setBothErrorE(true)
      setBothErrorP(true)
    }else{
      setBothErrorE(emailtest)
      setBothErrorP(phonetest)
    }
  },[email,number])
  return (
    <div className="mSummary-top-container">
      <ToastContainer />
      <div className="mSummary-headder">
        <Link to="/">
          <div className="mSummary-arrow-container">
            <img
              className="mSummary-arrow-img"
              src={process.env.PUBLIC_URL + "./MobileAssets/arrow-left.png"}
              alt={"Arrow left"}
            />
          </div>
        </Link>
        <div className="mSummary-arrow-text">
          <div>Automation Plan : Summary</div>
        </div>
      </div>
      <div className="mSummary-top-summary">
        <div className="mSummary-top-summary-inner-container">
          <div className="mSummary-Details">
            <div className="mSummary-text-heading">
              {data ? data.planName : "NOT Selected Plan"}
            </div>
            <div className="mSummary-text-subheading">
              Automate a total of {data?.appliances} appliances
            </div>
          </div>
          <div className="mSummary-price">₹ {data?.rate}</div>
        </div>
      </div>
      <div className="mSummary-contact-form" style={{ paddingBottom: "15px" }}>
        <form>
          <div className="mSummary-text-name">Personal details</div>
          <div
            className="input-group mb-1 custom"
            style={{
              border:
                emailError === false
                  ? "0.5px solid rgba(255, 255, 255, 0.8)"
                  : "0.5px solid #fe0000",
            }}
          >
            <div className="input-group-prepend">
              <img
                className="mSummary-user-img"
                src={process.env.PUBLIC_URL + "./MobileAssets/userIcon.png"}
                alt={"name"}
              />
            </div>
            <input
              type="email"
              className="inputBox"
              value={email}
              onBlur={() => handleOnChange(email)}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your email"
              aria-label="email"
              aria-describedby="basic-addon1"
            />
          </div>
          {emailError === true ? (
            <div className="field-error">
              Please enter a valid email address.
            </div>
          ) : null}
          <div
            className="input-group mb-1 custom"
            style={{
              border: `${
                phoneError === true
                  ? "0.5px solid #fe0000"
                  : "0.5px solid rgba(255, 255, 255, 0.8)"
              }`,
            }}
          >
            <div className="input-group-prepend">
              <img
                className="mSummary-user-img"
                src={process.env.PUBLIC_URL + "./MobileAssets/phoneIcon.png"}
                alt={"phone"}
              />
            </div>
            <input
              type="number"
              className="inputBox"
              placeholder="Phone no"
              onChange={(e) => setNumber(e.target.value)}
              aria-label="phone"
              onBlur={(e) => checkNumber(e.target.value)}
              aria-describedby="basic-addon1"
            />
          </div>
          {phoneError === true ? (
            <div className="field-error">
              Please enter a valid phone number.
            </div>
          ) : null}
          {voucherDiscount === "" && (
            <div className="wab-input-msummary-vocher">
              <div
                className="input-group mb-1 custom"
                style={{
                  border:
                    voucherError === ""
                      ? "0.5px solid rgba(255, 255, 255, 0.8)"
                      : "0.5px solid #fe0000",
                  backgroundColor:
                    voucherError === ""
                      ? "transparent"
                      : "rgba(252, 0, 0, 0.13)",
                }}
              >
                <div className="input-group-prepend vocher-input">
                  <img
                    className="mSummary-user-img"
                    src={process.env.PUBLIC_URL + "./MobileAssets/voucher.png"}
                    alt={"name"}
                  />
                </div>
                <input
                  type="text"
                  className="inputBox"
                  placeholder="Voucher Code"
                  ref={voucherCodePhone}
                  style={{
                    backgroundColor:
                      voucherError === ""
                        ? "transparent"
                        : "rgba(252, 0, 0, 0.13)",
                    color: voucherError === "" ? "#ffffff" : "#fe0000",
                    placeholderColor:
                      voucherError === "" ? "#ffffff" : "#fe0000",
                  }}
                  aria-describedby="basic-addon1"
                />
                <div
                  className="mSummary-vocher-button"
                  style={{
                    backgroundColor: `${
                      applyBtn === "processing..." ? "#cccccc" : "#2050FF"
                    }`,
                    cursor: "pointer",
                    pointerEvents: `${
                      applyBtn === "processing..." ? "none" : ""
                    }`,
                  }}
                  onClick={() => getVoucherStataus(voucherCodePhone)}
                >
                  {applyBtn}
                </div>
              </div>
            </div>
          )}
          {voucherError !== "" && (
            <div
              style={{ color: "#fe0000", fontSize: "14px" }}
              className="voucher-error"
            >
              {voucherError}
            </div>
          )}
          {voucherDiscount !== "" &&
            window.matchMedia("(max-width: 460px)").matches === true && (
              <Captcha setCaptchaSuccess={setCaptchaSuccess} captcha={captcha} setCaptcha={setCaptcha}   captchaEnter={captchaEnter} setCaptchaEnter={setCaptchaEnter} captchaError={captchaError}/>

            )}
          {voucherDiscount !== "" && (
            <div className="wab-input-msummary-vocher">
              <div
                className="input-group mb-1 custom voucher-successfully-applied"
                style={{
                  border: " 0.5px solid rgba(0, 243, 127, 0.80)",
                  backgroundColor: " rgba(0, 241, 53, 0.16)",
                  justifyContent: "left",
                }}
              >
                <div className="input-group-prepend vocher-input">
                  <img
                    className="mSummary-user-img"
                    src={process.env.PUBLIC_URL + "./MobileAssets/voucher.png"}
                    alt={"name"}
                  />
                </div>
                {voucherDiscount}
                <span
                  style={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setVoucherDiscount("");
                    setVoucherData({});
                    setVoucherDisclamair("")
                  }}
                >
                  x
                </span>
              </div>
            </div>
          )}
            {voucherDisclamair !== "" && (
            <div className="wab-input-msummary-vocher">
              <div
                className="mb-1"
                style={{
                  border: " 0.5px solid #e1e1e1",
                  backgroundColor: " #d1c9c973",
                  height:"auto",
                  width:"90%",
                  margin:"auto",
                  marginTop: "14px",
                  borderRadius: "6px",
                  display:"flex",
                  alignItems: "center",
                  gap: "12px",
                  padding: "6px 10px",
                }}
              >
                
                  <img
                    className="mSummary-user-img"
                    src={process.env.PUBLIC_URL + "./MobileAssets/voucher.png"}
                    alt={"name"}
                  />
          
                <div style={{
                  color: "#FFF",
                  fontFamily: "Manrope",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "19px",
                   textAlign:"justify"
                }}>
                {voucherDisclamair}
                </div>
                
              </div>
            </div>
          )}
          {voucherConsumeError !== "" && (
            <div
              style={{ color: "#fe0000", fontSize: "14px" }}
              className="voucher-error"
            >
              {voucherConsumeError}
            </div>
          )}
        </form>
      </div>
      {/* Select Plan */}
      <div
        className="mSummary-contact-form"
        style={{
          paddingBottom: "15px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="mSummary-text-name">Select Plan</div>
        <div
          className="input-group pl-2 custom-select"
          onClick={() => setSelectPopMobile(!selectPopMobile)}
        >
          <div className="selectedValue" style={{ cursor: "pointer" }}>
            <div>
              {defaultSelectedPlan.planName}{" "}
              <span style={{ color: "#208C4B", paddingLeft: "5px" }}>
                ₹ {defaultSelectedPlan.cost}/day
              </span>
            </div>
            <CaretDown
              color="white"
              style={{
                position: "absolute",
                right: "10px",
                top: "14px",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        {selectPopMobile === true ? (
          <div className="popBox">
            {PlanCost.filter((plan) => plan !== defaultSelectedPlan).map(
              (plan) => {
                return (
                  <div
                    className="selectedValue"
                    style={{
                      height: "43px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setDefaultSelectedPlan(plan);
                      setSelectPopMobile(false);
                    }}
                  >
                    {plan.planName}{" "}
                    <span style={{ color: "#208C4B", paddingLeft: "5px" }}>
                      ₹ {plan.cost}/day
                    </span>
                  </div>
                );
              }
            )}
          </div>
        ) : null}
      </div>

      <div className="mSummary-plan-Summary">
        <div className="mSummary-plan-Summary-upperDetail">
          <div className="mSummary-single-detail-container">
            <div className="mSummary-single-detail-name">Subscription Rate</div>
            <div className="mSummary-single-detail-result">
              ₹ {defaultSelectedPlan.cost}/day
            </div>
          </div>
          <div className="mSummary-single-detail-container">
            <div className="mSummary-single-detail-name">Plan Fee</div>
            <div className="mSummary-single-detail-result">
              ₹{" "}
              {data.appliances ? data.appliances * defaultSelectedPlan.days : 0}
            </div>
          </div>
          <div className="mSummary-single-detail-container">
            <div className="mSummary-single-detail-name">
              Security deposit (Refundable)
            </div>
            <div className="mSummary-single-detail-result">
              ₹ {data.appliances ? data.appliances * 100 : 0}
            </div>
          </div>
          <div className="mSummary-single-detail-container">
            <div className="mSummary-single-detail-name">Installation fee</div>
            <div className="mSummary-single-detail-result">
              ₹ {data.rate ? installation : 0}
            </div>
          </div>
          {Discount !== 0 ? (
            <div className="mSummary-single-detail-container">
              <div className="mSummary-single-detail-name">
                Voucher Discount
              </div>
              <div className="mSummary-single-detail-resultD">- ₹ 100</div>
            </div>
          ) : null}
          <div className="mSummary-single-detail-container">
            <div className="mSummary-single-detail-name">Taxes & charges</div>
            <div className="mSummary-single-detail-result">
              ₹{" "}
              {data.appliances
                ? (
                    (data.appliances * defaultSelectedPlan.days +
                      installation) *
                    0.18
                  ).toFixed(2)
                : 0}
            </div>
          </div>
        </div>
        <div className="mSummary-plan-Summary-lowerDetail">
          <div className="mSummary-plan-Total-container">
            <div className="mSummary-plan-Total-text">Total</div>
            <div className="mSummary-plan-Total-result">
              ₹
              {data.appliances
                ? (
                    (data.appliances * defaultSelectedPlan.days +
                      installation) *
                      1.18 +
                    data.appliances * 100 -
                    Discount
                  ).toFixed(2)
                : 0}
            </div>
          </div>
        </div>
      </div>
      {Object.keys(voucherData).length === 0 && (
        <div className="mSummary-plan-partial-summary">
          <span className="mSummary-partial-offer">
            <span className="mSummary-partial-offer-text">
              INTRODUCTORY OFFER
            </span>
          </span>
          <div className="mSummary-plan-patial">
            <div className="mSummary-partial-post-text-container">
              <div className="mSummary-partial-post-text">
                Pay booking amount
              </div>
              <div className="mSummary-partial-post-amount">
                ₹{" "}
                {data.appliances
                  ? (
                      (data.appliances * defaultSelectedPlan.days +
                        installation) *
                        1.18 +
                      data?.appliances * 100 -
                      300
                    ).toFixed(2)
                  : 0}{" "}
                post installation
              </div>
            </div>
            <div className="mSummary-partial-post-amount-result">₹ 300</div>
          </div>
        </div>
      )}

      <div className="mSummary-contact-form-wab">
        <div>
          <form>
            <div className="mSummary-text-name">Details</div>
            <div className="wab-input-msummary">
              <div
                className="input-group mb-1 custom"
                style={{
                  border:
                    emailError === false
                      ? "0.5px solid rgba(255, 255, 255, 0.8)"
                      : "0.5px solid #fe0000",
                }}
              >
                <div className="input-group-prepend">
                  <img
                    className="mSummary-user-img"
                    src={process.env.PUBLIC_URL + "./MobileAssets/userIcon.png"}
                    alt={"name"}
                  />
                </div>
                <input
                  type="email"
                  className="inputBox"
                  value={email}
                  onBlur={() => handleOnChange(email)}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your email"
                  aria-label="email"
                  aria-describedby="basic-addon1"
                />
              </div>
              {emailError === true ? (
                <div className="field-error">
                  Please enter a valid email address.
                </div>
              ) : null}
            </div>
            <div className="wab-input-msummary">
              <div
                className="input-group mb-1 custom"
                style={{
                  border: `${
                    phoneError === true
                      ? "0.5px solid #fe0000"
                      : "0.5px solid rgba(255, 255, 255, 0.8)"
                  }`,
                }}
              >
                <div className="input-group-prepend">
                  <img
                    className="mSummary-user-img"
                    src={
                      process.env.PUBLIC_URL + "./MobileAssets/phoneIcon.png"
                    }
                    alt={"phone"}
                  />
                </div>
                <input
                  type="number"
                  className="inputBox"
                  placeholder="Phone no"
                  aria-label="phone"
                  onChange={(e) => setNumber(e.target.value)}
                  onBlur={(e) => checkNumber(e.target.value)}
                  aria-describedby="basic-addon1"
                />
              </div>
              {phoneError === true ? (
                <div className="field-error" style={{ paddingBottom: "15px" }}>
                  Please enter a valid phone number.
                </div>
              ) : null}
            </div>
          </form>
        </div>
        {
          voucherDiscount === "" && (
            <div className="wab-input-msummary-vocher">
              <div
                className="input-group mb-1 custom-vocher"
                style={{
                  border:
                    voucherError === ""
                      ? "0.5px solid rgba(255, 255, 255, 0.8)"
                      : "0.5px solid #fe0000",
                  backgroundColor: voucherError === "" ? "transparent" : "rgba(252, 0, 0, 0.13)",
                }}
              >
                <div className="input-group-prepend vocher-input"  >
                  <img
                    className="mSummary-user-img"
                    src={process.env.PUBLIC_URL + "./MobileAssets/voucher.png"}
                    alt={"name"}
                  />
                </div>
                <input
                  type="text"
                  className="inputBox"
                  placeholder="Voucher Code"
                  aria-label="email"
                  ref={voucherCode}
                  style={{
                    backgroundColor: voucherError === "" ? "transparent" : "rgba(252, 0, 0, 0.13)",
                    color: voucherError === "" ? "#ffffff" : "#fe0000",
                    placeholderColor: voucherError === "" ? "#ffffff" : "#fe0000",
                  }}
                  aria-describedby="basic-addon1"
                />
                <div
                  className="mSummary-vocher-button"
                  style={{
                    backgroundColor: `${applyBtn === "processing..." ? "#cccccc" : "#2050FF"}`,
                    cursor: "pointer",
                    pointerEvents: `${applyBtn === "processing..." ? "none" : ""}`
                  }}
                  onClick={() => getVoucherStataus(voucherCode)}
                >
                  {applyBtn}
                </div>


              </div>


            </div>)
        }
        {
          voucherDiscount !== "" && (
            <Captcha setCaptchaSuccess={setCaptchaSuccess} captcha={captcha} setCaptcha={setCaptcha}   captchaEnter={captchaEnter} setCaptchaEnter={setCaptchaEnter} captchaError={captchaError}/>
          )
        }
        {
          voucherError !== "" && <div style={{ color: "#fe0000", fontSize: "14px" }} className="voucher-error">{voucherError}</div>
        }
     
        {
          voucherDiscount !== "" && (
            <div className="wab-input-msummary-vocher">

              <div
                className="input-group mb-1 custom-vocher voucher-successfully-applied"
                style={{
                  border: " 0.5px solid rgba(0, 243, 127, 0.80)",
                  backgroundColor: " rgba(0, 241, 53, 0.16)",
                }}
              >
                <div className="input-group-prepend vocher-input"  >
                  <img
                    className="mSummary-user-img"
                    src={process.env.PUBLIC_URL + "./MobileAssets/voucher.png"}
                    alt={"name"}
                  />
                </div>
                {voucherDiscount}
                <span
                  style={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setVoucherDiscount("")
                    setVoucherData({})
                    setVoucherDisclamair("")
                  }}
                >x</span>
              </div>

            </div>
          )
        }
         {
          voucherDisclamair !== "" && (
            <div className="wab-input-msummary-vocher">

              <div
                className="input-group mb-1 custom-vocher voucher-successfully-applied"
                style={{
                  border: " 0.5px solid #e1e1e1",
                  backgroundColor: " #d1c9c973",
                }}
              >
                <div className="input-group-prepend vocher-input"  >
                  <img
                    className="mSummary-user-img"
                    src={process.env.PUBLIC_URL + "./MobileAssets/voucher.png"}
                    alt={"name"}
                  />
                </div>
                {voucherDisclamair}
             
              </div>

            </div>
          )
        }
        {
          (voucherConsumeError !== "" && window.matchMedia('(min-width: 760px)').matches === true) && <div style={{ color: "#fe0000", fontSize: "14px" }} className="voucher-error">{voucherConsumeError}</div>
        }
      </div>

      {botherrorE === true && botherrorP === true ? (
        <div className="mSummary-pay-button-container">
          <div
            className="mSummary-pay-button"
            style={{
              backgroundColor: `${wabLoad === true ? "#cccccc" : "#2050FF"}`,
            }}
            onClick={() => {
              if (
                Object.keys(voucherData).length !== 0 
              ) {
              if(captcha === captchaEnter){
                setCaptchaError(false)
                consumeVocherCode(voucherCodePhone);
              }else{
                
                setCaptchaError(true)
              }
              } else {
                registerLeadAndLink();
              }
              trackContact({
                name: `${email} webapp-lead`,
                email,
                phone: number,
                city: "unkown",
              });
              initiateCheckout({
                appliances: data.appliances,
                totalAmount: Number(
                  (
                    (data?.rate + installation) * 1.18 +
                    data?.appliances * 100
                  ).toFixed(2)
                ),
                planId: "Monthly",
                packageId: data.planId,
                fee: data?.rate,
              });
            }}
          >
            <div className="mSummary-pay-button-text">
              {Object.keys(voucherData).length !== 0 
                ? "Register"
                : "Pay ₹ 300"}
            </div>
          </div>
        </div>
      ) : (
        <div className="mSummary-pay-button-container">
          <div
            className="mSummary-pay-button"
            style={{
              backgroundColor: "rgb(213 222 255 / 30%)",
            }}
            onClick={() => {
              if (
                Object.keys(voucherData).length !== 0 
              ) {
              if(captcha === captchaEnter){
                setCaptchaError(false)
                consumeVocherCode(voucherCodePhone);
              }else{
              
                setCaptchaError(true)
              }
              } else {
                registerLeadAndLink();
                checkNumber(number);
              }
            }}
          >
            <div className="mSummary-pay-button-text">{Object.keys(voucherData).length !== 0 ? "Register" : "Pay ₹ 300"}</div>
          </div>
        </div>
      )}
    </div>
  );
}
